<template>
  <moe-page title="发票管理">
    <moe-inquire @search="invoiceSearch">
      <el-form-item label="订单状态">
        <moe-select v-model="invoiceParams.orderStatus" type="payStatus" placeholder="选择订单状态" clearable />
      </el-form-item>

      <el-form-item label="订单编号">
        <el-input v-model.trim="invoiceParams.orderNo" placeholder="输入订单编号" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="开票状态">
        <moe-select v-model="invoiceParams.state" type="invoiceStatus" placeholder="选择开票状态" clearable />
      </el-form-item>

      <el-form-item label="发票抬头">
        <el-input v-model.trim="invoiceParams.title" placeholder="输入发票抬头" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="手机号">
        <el-input v-model.trim="invoiceParams.phone" placeholder="输入手机号" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="创建时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([invoiceParams.startTime, invoiceParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <moe-table ref="invoiceTableRef" url="/shop/orderFapiao/list" :params="invoiceParams" :numberShow="false">
      <el-table-column label="订单编号" min-width="180" prop="orderNo" />

      <el-table-column prop="payStatus" label="订单状态" min-width="100">
        <template slot-scope="{ row }">
          {{ payStatusComputed(row) }}
        </template>
      </el-table-column>

      <el-table-column prop="amount" label="发票金额" min-width="100">
        <template slot-scope="{ row }">
          {{ `¥ ${row.amount}` }}
        </template>
      </el-table-column>


      <el-table-column prop="createTime" label="申请日期" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>

      <el-table-column prop="type" label="抬头类型" min-width="80">
        <template slot-scope="{ row }">
          {{ $moe_format.getInvoiceType(row.type) }}
        </template>
      </el-table-column>

      <el-table-column label="发票抬头" min-width="150" prop="title" :show-overflow-tooltip="true" />

      <el-table-column label="税号" min-width="150" prop="tfn" :show-overflow-tooltip="true" />

      <el-table-column prop="state" label="状态" min-width="120">
        <div :class="$moe_format.getInvoiceStatusColor(row.state)" slot-scope="{ row }">
          {{ $moe_format.getInvoiceStatus(row.state) }}
        </div>
      </el-table-column>

      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" icon="el-icon-view" size="mini" @click="req_getOrderFapiaoDetail('发票申请详情', 'detail', row)">查看详情</el-button>
          <el-button v-if="['PENDING'].includes(row.state)" type="primary" icon="el-icon-view" size="mini" @click="req_getOrderFapiaoDetail('上传发票', 'upload', row)">上传发票</el-button>
          <el-button v-if="['PENDING'].includes(row.state)" type="danger" size="mini" @click="req_getOrderFapiaoDetail('发票申请驳回', 'reject', row)">驳回</el-button>

          <el-button v-if="['WAIT_RED'].includes(row.state)" type="danger" size="mini" @click="req_getOrderFapiaoDetail('发票冲红', 'red', row)">冲红</el-button>
        </div>
      </el-table-column>
    </moe-table>

    <moe-dialog :show="showDialog" width="70%" :title="dialogTitle" @close="handleDialogClose()" :autoHeight="true">
      <moe-form ref="invoiceRef" :showBack="false" :model="invoiceData" :rules="rules">
        <el-form-item label="订单编号" prop="orderNo">
          <div class="df aic">
            <div class="mr-20">{{ invoiceData.orderNo }}</div>
            <el-button type="success" icon="el-icon-view" size="mini" @click="$moe_coordinator.navigateTo('/order/detail', { orderNo: invoiceData.orderNo })">查看订单详情</el-button>
          </div>
        </el-form-item>

        <el-form-item label="发票金额" prop="amount">
          <div class="color-danger">{{ `¥ ${invoiceData.amount}` }}</div>
        </el-form-item>

        <el-form-item label="当前状态" prop="state">
          <div :class="$moe_format.getInvoiceStatusColor(invoiceData.state)">
            {{ $moe_format.getInvoiceStatus(invoiceData.state) }}
          </div>
        </el-form-item>

        <el-form-item label="发票抬头" prop="title">
          {{ invoiceData.title }}
        </el-form-item>

        <el-form-item label="发票类型" prop="type">
          {{ `普通发票 - ${$moe_format.getInvoiceType(invoiceData.type)}` }}
        </el-form-item>

        <template v-if="invoiceData.type === 'COMPANY'">
          <el-form-item label="购方税号" prop="tfn">
            {{ invoiceData.tfn }}
          </el-form-item>

          <el-form-item label="开户银行" prop="bank">
            {{ invoiceData.bank || '-' }}
          </el-form-item>

          <el-form-item label="开户账号" prop="bankAccount">
            {{ invoiceData.bankAccount || '-' }}
          </el-form-item>

          <el-form-item label="企业电话" prop="companyPhone">
            {{ invoiceData.companyPhone || '-' }}
          </el-form-item>

          <el-form-item label="企业地址" prop="companyAddress">
            {{ invoiceData.companyAddress || '-' }}
          </el-form-item>
        </template>

        <el-form-item label="联系方式" prop="phone">
          {{ invoiceData.phone }}
        </el-form-item>



        <el-form-item label="上传发票" prop="attachFile" v-if="['upload'].includes(dialogType) || invoiceData.attachFile">
          <moe-upload-file
            :readonly="['TICKETED'].includes(invoiceData.state)"
            v-model="invoiceData.attachFile"
            ossKey="SHOP_FAPIAO"
            upload-text="上传发票"
            :default-file-list="attachFileList"
            :suffix="['pdf']"
            @change="$refs.invoiceRef.validateField('attachFile')" />
        </el-form-item>

        <el-form-item label="上传发票" v-else>
          <moe-image  width="100px" height="100px" :src="invoiceData.attachFile">
            <template #error>未上传发票</template>
          </moe-image>
        </el-form-item>

        <el-form-item label="驳回原因" prop="rejectReason" v-if="['reject'].includes(dialogType)">
          <el-input v-model="invoiceData.rejectReason" type="textarea" :rows="5" placeholder="请输入驳回原因" maxlength="500" clearable />
        </el-form-item>
      </moe-form>

      <template slot="footer" v-if="['upload', 'reject', 'red'].includes(dialogType)">
        <el-button @click="handleDialogClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">{{ dialogTypeOptions[dialogType] }}</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'FinanceManageInvoiceList',
  data() {
    const verify = this.$moe_verify.verifyForm
    return {
      invoiceParams: {
        pageNum: 1,
        pageSize: 10,
        orderStatus: 'COMPLETED',
        orderNo: '',
        state: 'PENDING',
        title: '',
        startTime: '',
        endTime: '',
        phone: ''
      },
      dateTime: [],
      showDialog: false,
      dialogTitle: '',
      dialogType: '',
      invoiceData: {
        orderNo: '',
        amount: '',
        state: '',
        title: '',
        type: '',
        tfn: '',
        bank: '',
        bankAccount: '',
        companyPhone: '',
        companyAddress: '',
        phone: '',
        rejectReason: '',
        attachFile: '',
      },
      attachFileList: [],
      rules: {
        attachFile: verify.isEmpty('请上传发票'),
        rejectReason: verify.isEmpty('请输入驳回原因'),
      },
      dialogTypeOptions: {
        upload: '上传发票',
        reject: '驳回',
        red: '冲红'
      }
    }
  },
  methods: {
    invoiceSearch(isSearch) {
      if (!isSearch) {
        this.invoiceParams = {
          pageNum: 1,
          pageSize: 10,
          orderStatus: 'COMPLETED',
          orderNo: '',
          state: 'PENDING',
          title: '',
          startTime: '',
          endTime: '',
          phone: ''
        }
        this.dateTime = [];
      }

      this.$refs['invoiceTableRef'].searchData();
    },
    /** 订单状态 */
    payStatusComputed({ orderStatus, orderItemList }) {
      if (['PENDING_PAYMENT'].includes(orderStatus)) {
        if (orderItemList.filter(({ deposit, isPayDeposit }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayDeposit).length) {
          return `定金待支付`
        } else if (orderItemList.filter(({ deposit, isPayBalance }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayBalance).length) {
          return `尾款待支付`
        } else {
          return `待付款`
        }
      } else {
        return this.$moe_format.getPayStatus(orderStatus);
      }
    },
    handleDialogClose() {
      this.showDialog = false;
      this.attachFileList = [];
      this.invoiceData = {
        orderNo: '',
        amount: '',
        state: '',
        title: '',
        type: '',
        tfn: '',
        bank: '',
        bankAccount: '',
        companyPhone: '',
        companyAddress: '',
        phone: '',
        rejectReason: '',
        attachFile: '',
      },
      this.$refs['invoiceRef'].clearValidate();
    },
    /** 弹框确认 */
    handleConfirm() {
      this.$refs['invoiceRef'].validate(() => {
        if (['detail', 'upload'].includes(this.dialogType)) {
          this.req_uploadOrderFapiao();
        }
        if (['reject'].includes(this.dialogType)) {
          this.req_rejectOrderFapiao();
        }
        if (['red'].includes(this.dialogType)) {
          this.req_redOrderFapiao();
        }
      })
    },
    /** 查询发票详情 */
    req_getOrderFapiaoDetail(dialogTitle, dialogType, { orderNo }) {
      this.$moe_api.INVOICE_API.getOrderFapiaoDetail({ orderNo }).then((data) => {
        if (data.code === 200) {
          let { orderNo, amount, state, title, type, tfn, bank, bankAccount, companyPhone, companyAddress, phone, rejectReason, attachFile } = data.result;
          this.invoiceData = {
            orderNo,
            amount,
            state,
            title,
            type,
            tfn,
            bank,
            bankAccount,
            companyPhone,
            companyAddress,
            phone,
            rejectReason,
            attachFile: attachFile ? this.$moe_yunHelper.formatterImageUrl(attachFile) : '',
          };

          if (attachFile) {
            this.attachFileList = [
              {
                url: attachFile,
                tempFilePath: this.$moe_yunHelper.getTempFilePath(attachFile),
              }
            ]
          }

          this.dialogTitle = dialogTitle;
          this.dialogType = dialogType;
          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 上传发票 */
    req_uploadOrderFapiao() {
      this.$moe_layer.confirm(`请确认发票信息是否无误？`, () => {
        const { orderNo, attachFile } = this.invoiceData
        this.$moe_api.INVOICE_API.uploadOrderFapiao({
          orderNo,
          attachFile
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('上传发票成功 !', {
              completionHandler: () => {
                this.handleDialogClose();
                this.invoiceSearch(true);
              }
            })
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 开票驳回 */
    req_rejectOrderFapiao() {
      this.$moe_layer.confirm(`您确定发票驳回吗?`, () => {
        const { orderNo, rejectReason } = this.invoiceData
        this.$moe_api.INVOICE_API.rejectOrderFapiao({
          orderNo,
          rejectReason
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('驳回发票成功 !', {
              completionHandler: () => {
                this.handleDialogClose();
                this.invoiceSearch(true);
              }
            })
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 发票冲红 */
    req_redOrderFapiao() {
      this.$moe_layer.confirm(`您确定发票冲红吗?`, () => {
        this.$moe_api.INVOICE_API.redOrderFapiao({
          orderNo: this.invoiceData.orderNo
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('发票冲红成功 !', {
              completionHandler: () => {
                this.handleDialogClose();
                this.invoiceSearch(true);
              }
            })
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  }
}
</script>

<style lang="scss">

</style>